import React from 'react';
import DBIcon from '../../db_icon.png';
import 'bootstrap/dist/css/bootstrap.css';
import './defaultNavbar.scss';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { LinkContainer } from 'react-router-bootstrap';
import { useLocation } from 'react-router-dom';
import AsciiChar from '../asciiChar/AsciiChar';

function DefaultNavbar({ fixed, userLogout }) {
  const userObj = JSON.parse(localStorage.getItem('user'));
  const location = useLocation();

  const handleLogout = () => {
    userLogout();
  };

  const isActive = (path) => location.pathname === path;

  return (
    <div id="default-navbar">
      <Navbar collapseOnSelect expand="lg" variant="dark" bg="dark" fixed={fixed}>
        <Container fluid>
          <LinkContainer to="/home">
            <Nav.Link>
              <Navbar.Brand>
                <img
                  src={DBIcon}
                  width="30"
                  height="30"
                  className="d-inline-block align-top"
                  alt="Ananta logo"
                />
              </Navbar.Brand>
            </Nav.Link>
          </LinkContainer>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <LinkContainer to="/home">
                <Nav.Link className={isActive('/home') ? 'active' : ''}>Dashboard</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/workout-calendar">
                <Nav.Link className={isActive('/workout-calendar') ? 'active' : ''}>Calendar</Nav.Link>
              </LinkContainer>              
              <LinkContainer to="/exercises">
                <Nav.Link className={isActive('/exercises') ? 'active' : ''}>Exercises</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/profile">
                <Nav.Link className={isActive('/profile') ? 'active' : ''}>Profile</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/workouts">
                <Nav.Link className={isActive('/workouts') ? 'active' : ''}>Workouts</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/workout-plan">
                <Nav.Link className={isActive('/workout-plan') ? 'active' : ''}>Workout Plan</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/workout-templates">
                <Nav.Link className={isActive('/workout-templates') ? 'active' : ''}>Workout Templates</Nav.Link>
              </LinkContainer>
              <Nav.Link onClick={handleLogout}>Logout</Nav.Link>
            </Nav>
          </Navbar.Collapse>
          {userObj && (
            <Nav className="hidden-xs">
              <LinkContainer to="/home" style={{ color: 'white' }}>
                <Nav.Link>
                  Hi {userObj.firstName || ''} <AsciiChar />
                </Nav.Link>
              </LinkContainer>
            </Nav>
          )}
        </Container>
      </Navbar>
    </div>
  );
}

export default DefaultNavbar;
