import React from 'react';
import { Form, Container, Row, Col, Button, Card, Table } from 'react-bootstrap';
import moment from 'moment';
import './userProfileForm.scss';

const UserProfileForm = ({
  formData,
  handleInputChange,
  handleFormSubmit,
  goals = [], // Default empty array
  setGoals,
  handleGoalChange,
  handleAddGoal,
  handleRemoveGoal,
}) => {
  const isMobile = window.innerWidth <= 768;

  const mostRecentWeight =
    formData.weights && formData.weights.length > 0
      ? formData.weights[formData.weights.length - 1].weight
      : 'N/A';

  return (
    <div className="user-profile-form-container">
      <Form onSubmit={handleFormSubmit}>
        <Container>
          {/* User Details Section */}
          <h3>User Details</h3>
          <Row>
            <Col md={6}>
              <Form.Group controlId="firstName">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  name="firstName"
                  value={formData.firstName || ''}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="lastName">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  name="lastName"
                  value={formData.lastName || ''}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={formData.email || ''}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="zipCode">
                <Form.Label>Zip Code</Form.Label>
                <Form.Control
                  type="text"
                  name="zipCode"
                  value={formData.zipCode || ''}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group controlId="birthday">
                <Form.Label>Birthday</Form.Label>
                <Form.Control
                  type="date"
                  name="birthday"
                  value={
                    formData.birthday
                      ? moment.utc(formData.birthday).format('YYYY-MM-DD') // Use UTC to format the date
                      : ''
                  }
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group controlId="gender">
                <Form.Label>Gender</Form.Label>
                <Form.Select
                  name="gender"
                  value={formData.gender || ''}
                  onChange={handleInputChange}
                >
                  <option value="">Select Gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Other</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>          
          <Row>
            <Col md={6}>
              <Form.Group controlId="height">
                <Form.Label>Height (inches)</Form.Label>
                <Form.Control
                  type="number"
                  name="height"
                  value={formData.height || ''}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
          </Row>

          {/* Weights Section */}
          <h3>Weight</h3>
          <Row>
            <Col md={6}>
              <Card>
                <Card.Body>
                  <Card.Title>Most Recent Weight</Card.Title>
                  <Card.Text>
                    {mostRecentWeight} lbs
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6}>
              <Form.Group controlId="newWeight">
                <Form.Label>Add New Weight (lbs)</Form.Label>
                <Form.Control
                  type="number"
                  name="newWeight"
                  value={formData.newWeight || ''}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
          </Row>

          {/* Goals Section */}
          <h3>Goals</h3>
          {isMobile ? (
            goals.map((goal, index) => (
              <Card className="mb-3" key={index}>
                <Card.Body>
                  <Form.Group controlId={`type-${index}`}>
                    <Form.Label>Type</Form.Label>
                    <Form.Select
                      value={goal.type || ''}
                      onChange={(e) => handleGoalChange(e, index, 'type')}
                    >
                      <option value="">Select Goal Type</option>
                      <option value="Weight">Weight</option>
                      <option value="Workout Days Per Week">Workout Days Per Week</option>
                      <option value="Workout Days Per Month">Workout Days Per Month</option>
                      <option value="Distance">Distance</option>
                      <option value="Calories Burned">Calories Burned</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group controlId={`target-${index}`} className="mt-3">
                    <Form.Label>Target</Form.Label>
                    <Form.Control
                      type="number"
                      value={goal.target || ''}
                      onChange={(e) => handleGoalChange(e, index, 'target')}
                    />
                  </Form.Group>
                  <Button
                    variant="danger"
                    className="mt-3"
                    onClick={() => handleRemoveGoal(index)}
                  >
                    Remove Goal
                  </Button>
                </Card.Body>
              </Card>
            ))
          ) : (
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Target</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {goals.map((goal, index) => (
                  <tr key={index}>
                    <td>
                      <Form.Select
                        value={goal.type || ''}
                        onChange={(e) => handleGoalChange(e, index, 'type')}
                      >
                        <option value="">Select Goal Type</option>
                        <option value="Weight">Weight</option>
                        <option value="Workout Days Per Week">Workout Days Per Week</option>
                        <option value="Workout Days Per Month">Workout Days Per Month</option>
                        <option value="Distance">Distance</option>
                        <option value="Calories Burned">Calories Burned</option>
                      </Form.Select>
                    </td>
                    <td>
                      <Form.Control
                        type="number"
                        value={goal.target || ''}
                        onChange={(e) => handleGoalChange(e, index, 'target')}
                      />
                    </td>
                    <td>
                      <Button variant="danger" onClick={() => handleRemoveGoal(index)}>
                        Remove
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
          <Button variant="success" className="mt-3" onClick={handleAddGoal}>
            Add Goal
          </Button>
          <Button variant="primary" type="submit" className="mt-3 ms-2">
            Save Changes
          </Button>
        </Container>
      </Form>
    </div>
  );
};

export default UserProfileForm;
