import React, { useEffect, useState } from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import './userWeightChart.scss';

// Linear regression function to find the slope and intercept
const linearRegression = (data) => {
  const n = data.length;
  const sumX = data.reduce((acc, val, idx) => acc + idx, 0);
  const sumY = data.reduce((acc, val) => acc + val.weight, 0);
  const sumXY = data.reduce((acc, val, idx) => acc + idx * val.weight, 0);
  const sumX2 = data.reduce((acc, _, idx) => acc + idx * idx, 0);

  const slope = (n * sumXY - sumX * sumY) / (n * sumX2 - sumX * sumX);
  const intercept = (sumY - slope * sumX) / n;

  return { slope, intercept };
};

// Function to calculate the trend line using linear regression
const getTrendLine = (data, slope, intercept) => {
  return data.map((_, idx) => ({
    date: data[idx].date,
    weight: slope * idx + intercept, // Apply linear regression formula
  }));
};

const UserWeightChart = ({ showTitle = true, user }) => {
  // const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
  const [chartData, setChartData] = useState([]);

  // Function to format dates for X-axis
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options); // Use locale for better format
  };

  useEffect(() => {
    // Ensure weights are sorted by date (ascending order)
    const sortedWeights = user?.weights.sort((a, b) => new Date(a.date) - new Date(b.date));

    // Prepare the chart data
    const data = sortedWeights?.map(weight => ({
      date: formatDate(weight.date), 
      weight: weight.weight
    }));

    // Calculate the linear regression (trend line)
    const { slope, intercept } = linearRegression(data);
    const trendLine = getTrendLine(data, slope, intercept);

    // Find the minimum and maximum weight values
    const minWeight = Math.min(...sortedWeights.map(weight => weight.weight));
    const maxWeight = Math.max(...sortedWeights.map(weight => weight.weight));

    // Round the values to the nearest whole numbers with a small buffer
    const roundedMinWeight = Math.floor(minWeight); // Round down to the nearest whole number
    const roundedMaxWeight = Math.ceil(maxWeight); // Round up to the nearest whole number

    // Add some buffer space (e.g., 5 units) to ensure the chart doesn't touch the axis boundaries
    const adjustedMinWeight = roundedMinWeight - 5;
    const adjustedMaxWeight = roundedMaxWeight + 5;

    // Update the state with the prepared data and trend line
    setChartData({
      data,
      trendLine,
      minWeight: adjustedMinWeight,
      maxWeight: adjustedMaxWeight
    });
  }, [user]); // Re-run when user data changes

  return (
    <div id="user-weight-chart" style={{ width: "100%", padding: "0" }}>
      {showTitle && (
        <h4 style={{ marginBottom: "10px", fontSize: "1rem" }}>Weight History</h4>
      )}
      <ResponsiveContainer width="100%" height={300}>
        <LineChart data={chartData.data} margin={{
              top: 20,
              right: 30,
              left: 0,
              bottom: 50,
          }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="date"
            angle={-45} // Adjust angle to -45 for better readability
            textAnchor="end"
            height={60} // Increase space between the x-axis and the chart
            tick={{ fontSize: 10 }} // Reduce font size of ticks
          />
          <YAxis domain={[chartData.minWeight, chartData.maxWeight]} />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="weight" stroke="#8884d8" name="Weight" />
          <Line 
            type="linear" 
            dataKey="weight" 
            stroke="#ff7300" 
            dot={false} 
            data={chartData.trendLine} 
            strokeDasharray="5 5"  // Dotted line
            name="Trend"  // Change the legend name here
          /> {/* Straight Dotted Trend Line */}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default UserWeightChart;
