import { React, useEffect, useState } from 'react';
import { Card, Container, Col, Form, Image, Row } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import './styles/profile.scss';
import axios from 'axios';
import _ from 'underscore';
import { API_URL } from '../assets/constants';
import moment from 'moment';

// IMAGES
import MaleImg from '../assets/images/icons/male_icon.svg';
import FemaleImg from '../assets/images/icons/female_icon.svg';

// Components
import PageHero from 'components/pageHero/PageHero';
import InfiniteHorizontalScroll from 'components/infiniteHorizontalScroll/InfiniteHorizontalScroll';
import UserProfileForm from 'components/userProfileForm/UserProfileForm';
import UserWeightChart from 'components/userWeightChart/UserWeightChart';

const ColorPanel = ({colorObj, handleClick, user}) => (
    <div className={"color-panel-container "} onClick={handleClick}>
      <div className={"color-panel " + (user.gradient == colorObj.gradient ? 'active' : '')} style={{background: colorObj.gradient}}></div>
      <p>{colorObj.name}</p>
    </div>
)

function Profile() {
  // State parameters
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
  const [formData, setFormData] = useState({ ...user, newWeight: '' });
  const [colorGradients, setColorGradients] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [goals, setGoals] = useState(user.goals || []);
  const [refreshBanner, setRefreshBanner] = useState(false); // New state for refreshing the banner

  useEffect(() => {
    refreshColorGradients();
  }, [user]);

  const refreshColorGradients = async () => {
    const colorGradients = await axios.get(API_URL + `/api/colorGradient/list`);
    if (colorGradients.data.length > 0) setColorGradients(colorGradients.data.sort((a,b) => a.name > b.name ? 1 : -1));
  }

  const updateUserGradient = (colorObj, callback) => {
    axios.put(API_URL + `/api/user/${user._id}/update`, {
      gradient: colorObj.gradient,
    })
      .then(() => {
        const newUserObj = {...user, gradient: colorObj.gradient};

        localStorage.setItem('user', JSON.stringify(newUserObj));
        setUser(newUserObj);        
        setRefresh(true);

        // We need to reset the refresh button to false so we can re-run this function and trigger a refresh of the PageHero component
        setTimeout(() => {
          setRefresh(false);
        }, 500);

        // handleClick --> props
        if (typeof callback == 'function') callback();      
      });
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleGoalChange = (e, index, field) => {
    const updatedGoals = [...goals];
    updatedGoals[index][field] = field === 'startDate' || field === 'endDate' ? new Date(e.target.value) : e.target.value;
    setGoals(updatedGoals);
  };

  const handleAddGoal = () => {
    setGoals([...goals, { type: '', target: 0, progress: 0, startDate: new Date(), endDate: null }]);
  };

  const handleRemoveGoal = (index) => {
    const updatedGoals = goals.filter((_, i) => i !== index);
    setGoals(updatedGoals);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const newWeight = parseFloat(formData.newWeight);

    // Prepare user data without duplicating weights
    const updatedUser = { ...formData, goals };

    // Only send the new weight if it's valid
    if (!isNaN(newWeight) && newWeight > 0) {
      updatedUser.weights = [{ weight: newWeight, date: new Date() }];
    } else {
      delete updatedUser.weights; // Prevent overwriting weights if no new weight is provided
    }

    try {
      // Send updated user data to the backend
      const response = await axios.put(`${API_URL}/api/user/${user._id}/update`, updatedUser);

      // Update local state
      setUser(response.data);
      localStorage.setItem('user', JSON.stringify(response.data));
      setFormData({ ...response.data, newWeight: '' }); // Clear the newWeight field
      setRefreshBanner((prev) => !prev); // Toggle refresh state
      alert('Profile updated successfully!');
    } catch (error) {
      console.error('Error updating profile:', error);
      alert('Failed to update profile. Please try again.');
    }
  };

  const handleAddWeight = async () => {
    const newWeight = parseFloat(formData.newWeight);

    if (isNaN(newWeight) || newWeight <= 0) {
      alert('Please enter a valid weight.');
      return;
    }

    const updatedWeights = [
      ...(formData.weights || []),
      { weight: newWeight, date: new Date() },
    ];

    try {
      // Update the user with the new weight
      const response = await axios.put(`${API_URL}/api/user/${user._id}/update`, {
        weights: [{ weight: newWeight, date: new Date() }],
      });

      // Update local user data and reset newWeight input
      setUser(response.data);
      localStorage.setItem('user', JSON.stringify(response.data));
      setFormData({ ...formData, newWeight: '' });
      alert('Weight added successfully!');
    } catch (error) {
      console.error('Error saving weight:', error);
      alert('Failed to save weight. Please try again.');
    }
  };

  return (
    <div id="profile-page">
      <PageHero copy={user.firstName + "'s " + "Profile"} refresh={refresh} refreshBanner={refreshBanner} cssOptions={{

      }} />

      <section id="img-section">
        <div id="wrapper" style={{ textAlign: 'center', marginBottom: '20px' }}>
          <div id="profile-img-container" style={{ display: 'inline-block', width: '150px', height: '150px', overflow: 'hidden', borderRadius: '50%', border: '3px solid #ccc' }}>
            <div id="profile-img" style={{ width: '100%', height: '100%' }}>
              <Image
                fluid
                src={user.gender === 'female' ? FemaleImg : MaleImg}
                style={{ objectFit: 'cover', width: '100%', height: '100%' }}
              />
            </div>
          </div>
        </div>
        <div style={{ textAlign: 'center', color: '#333' }}>
          <h1 id="user-name" style={{ margin: '0 0 10px', fontSize: '1.8rem', fontWeight: 'bold' }}>
            {user.firstName} {user.lastName}
          </h1>
          <div style={{ fontSize: '1rem', lineHeight: '1.6', padding: '0 10px' }}>
            <p style={{ margin: '5px 0' }}>
              <strong>Age:</strong> {user.birthday 
                ? moment().diff(moment.utc(user.birthday), 'years') 
                : 'N/A'}
            </p>
            <p style={{ margin: '5px 0' }}>
              <strong>Birthday:</strong> {user.birthday 
                ? moment.utc(user.birthday).format('MM/DD/YYYY') 
                : 'N/A'}
            </p>
            <p style={{ margin: '5px 0' }}>
              <strong>Height:</strong> {user.height
                ? `${Math.floor(user.height / 12)} ft ${user.height % 12} in`
                : 'N/A'}
            </p>
            <p style={{ margin: '5px 0' }}>
              <strong>Current Weight:</strong> {user.weights && user.weights.length > 0 
                ? `${user.weights[user.weights.length - 1].weight} lbs.` 
                : 'N/A'}
            </p>
            {user && <UserWeightChart showTitle={false} user={user} />}
          </div>
        </div>
      </section>

      <div className="spacer"></div>

      <section id="user-profile-form">
        <UserProfileForm
          formData={formData}
          handleInputChange={handleInputChange}
          handleFormSubmit={handleFormSubmit}
          handleAddWeight={handleAddWeight} // Pass this function
          goals={goals}
          handleGoalChange={handleGoalChange}
          handleAddGoal={handleAddGoal}
          handleRemoveGoal={handleRemoveGoal}
        />
      </section>

      <section id="color-panel-section">
        <h1 style={{textAlign: 'center'}}>Change Background</h1>

        <InfiniteHorizontalScroll>
          {
            colorGradients.length > 0 &&
            colorGradients.map((colorObj, i) => (
              <ColorPanel key={i} user={user} colorObj={colorObj} handleClick={() => updateUserGradient(colorObj)} />
            ))
          }
        </InfiniteHorizontalScroll>
      </section>      
    </div>
  );
}

export default Profile;
